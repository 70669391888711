import React from "react";
import "./Helper.css";

function HelperModule(props) {
  return (
    <div className="Helper-box">
      <div className="Helper-nav">
        <button className="Helper-close" onClick={()=>props.onCloseHelper(false)}></button>
      </div>
      <div className="Helper-content">
        {props.eng === true ? (
          <p>
            <strong>Explore!</strong>
            <ul>
              <li>Hold your left mouse button and move to enjoy the 3D environment.</li>
              <li>Hold shift to move horizontaly or verically.</li>
              <li>Don't be afraid to explore and try to click various elements.</li>
              <li>If you get lost, just refresh the page.</li>
            </ul>
          </p>
        ) : (
         　<p>
            <strong>探検してみて！</strong>
            <ul>
              <li>3Dが楽しめるように左マウスボタンを押しながら動かしてください。</li>
              <li>SHIFTを押しながら、水平にも垂直にも動かせます</li>
              <li>エレメントもキリックできます。</li>
              <li>もし、迷ったら、ページをページを更新してみてください。</li>
            </ul>
            </p>
            
        )}
      </div>
    </div>
  );
}

export default HelperModule;
