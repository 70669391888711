import "../App.css";
import "../Range.css";
import "./Home.css";
import { useRef, useEffect, useState } from "react";

import {Link} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom'

//img

import anim_gr from "../Videos/ispace-logo-animation-concept-graphic.mp4"
import anim_bl from "../Videos/ispace-logo-animation-concept-min-black.mp4"
import anim_wt from "../Videos/ispace-logo-animation-concept-min-white.mp4"
import isar_logo from "../Images/Isar-Aerospace-logo.png"

function Home(props) {
  const eng = props.eng
  
  const top = useRef();
  const about = useRef();
  const video = useRef()
  const story = useRef()
  const module = useRef()

  const navigate = useNavigate();

  const [videoSelected,setVideoSelected] = useState(anim_gr)
  const videoSelectedHandler = (vid) => {
     setVideoSelected(vid)
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          //console.log("uid", uid)
        } else {
          // User is signed out
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])


  const scrollTo = (to) => {
    to.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
      const home = document.getElementById("home");
      const hook = document.getElementById("hook");
      const handleScroll = () => {
        if (home.scrollTop > hook.getBoundingClientRect().top + 200 ) {
          setShowMenu(true);
        } else {
          setShowMenu(false);
        }

      };
        
      home.addEventListener('scroll', handleScroll);
      return () => {
      home.removeEventListener('scroll', handleScroll);
      };
    },[]);
  

  return (
    <main className="home" id="home">
      <div
       className="background-fixed"      
       />
      <div className="section_nav">
        <div className="nav-box" style={{opacity: "1"}} >
          <button onClick={()=> navigate("/home") }>H•ME</button>
          <button onClick={()=> navigate("/ispace")}>ISP⧍CE</button>
          <button onClick={()=> navigate("/isaraerospace")}>IS⧍R ⧍ER•SP⧍CE</button>
        </div>
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
      </div>
      <div id="hook" ref={about} className="section_wrapper section_wrapper-top">
          <span className="heading_wrapper-l">
            <h4 className="heading heading-about">⧍B•UT</h4>
            <a class="a_link" title="Go to Isar aerospace official site" href="https://www.isaraerospace.com/" target="_blank" rel="noreferrer">
                  <img className="isar_logo" alt="ispace logo" src={isar_logo}/>
            </a>        
          </span>
          <div className="block_wrapper">
            <div className="section_block section_block-about">
            {eng === true ? (  
              <p>
                Isar Aerospace is a German space startup which aims to launch its own orbital vehicle to space. This section has currently 1 interactive feature to tributes Isar aurospace first mission to space.
              </p>
            ) : (
              <p>
                Isar Aerospaceは、ドイツの宇宙スタートアップであり、独自の軌道打ち上げ機を宇宙へ送り出すことを目指しています。このセクションでは、Isar Aerospaceの初の宇宙ミッションを称えるためのインタラクティブな機能を1つご用意しています。
              </p>
            )}
            </div> 
          </div>
   
      </div>   
      <div  ref={story} className="section_wrapper-story">
        <div className="section_block section_block-spectrum">    
          <div className="link_box link_box-story">
                <Link to="/isaraerospace/spectrum" className="link_spectrum link-big">SPECTRUM</Link>
          </div>  
          <div className="section_block-text">
                    {eng === true ? (
                      <p>
                        Spectrum is a 2 Stage Orbital Launch Vehicle which is about to be tested in 2024/2025. Here you can explore it's fan 3D model with interactive features and more details.  
                        The provided scale, shape and design is not fully accurate and it is for entertainment only.                        
                        <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                        Open by clicking the spectrum button. Explore and be curious. After visiting the page, you can click <span
                                style={{
                                  color: "rgba(255, 68, 0, 1)",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                              >?</span> button to get more instructions. </p>
                      </p>
                    ) : (
                      <p>
                     Spectrumは、2024年から2025年にかけて試験が予定されている2段式の軌道打ち上げロケットです。ここでは、インタラクティブな機能を備えた3Dモデルを通じて、その詳細を探索できます。提供されているスケール、形状、デザインは完全に正確ではなく、娯楽目的のみであることをご承知おきください。<br/>   
                      <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                      ストーリーロゴをクリックして開いてください。探索し、好奇心を持ってください。スマートフォンでページを訪れている場合は、より良い体験のために画面を横向きに回転させてください。ページを訪れた後、<span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?
                        </span>ボタンをクリックして指示を取得できます。
                        </p>
                      </p>
                    )}
          </div>
        </div>
      </div>
      <span className="photo-by">Background photo by <a className="a_link" href="https://unsplash.com/@danesduet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniel Olah</a> on <a className="a_link" href="https://unsplash.com/photos/HNkgPFBShSw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </span>
    </main>
  );
}

export default Home;
