import { useFrame, useLoader, useThree } from '@react-three/fiber'
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { useRef, useState } from 'react'
import * as THREE from 'three'
import {LatheGeometry} from 'three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { 
    OrbitControls,
    Html,
    Stars,
    Sparkles,
    Text,
    PositionalAudio,
 } from '@react-three/drei'
import "./Modul.css";
import Jet from './Jet'

//MATERIALS
function MetalLightMaterial () {
    return (
    <meshPhysicalMaterial 
    color='#ffffff' 
    roughness = {0.01} 
    metalness = {0.8}
    reflectivity = {0.4} 
    side="DoubleSide" 
    emissive = '#656565'
    emissiveIntensity = {0.2}/>
    )
}

function MetalBlueMaterial () {
    return (
    <meshPhysicalMaterial 
    color='#00005e' 
    roughness = {0.4} 
    metalness = {0.3}
    reflectivity = {0.2} 
    side="DoubleSide" 
    emissive = "#000045"
     />
    )
}

function TopPartMaterial () {
    const isarLogoIcon = useLoader(TextureLoader, '../IsarAerospaceLogoIcon.png')
    return (
    <meshStandardMaterial 
        map={isarLogoIcon}
        color='#ffffff' 
        roughness = {0.01} 
        metalness = {0.8}
        reflectivity = {0.4} 
        side="DoubleSide" 
        emissive = '#656565'
        emissiveIntensity = {0.2}
    />
    )
}

function MiddlePartMaterial () {
    const isarLogoH = useLoader(TextureLoader, '../IsarAerospaceLogo.png')
    return (
    <meshStandardMaterial 
        map={isarLogoH}
        color='#ffffff' 
        roughness = {0.01} 
        metalness = {0.8}
        reflectivity = {0.4} 
        side="DoubleSide" 
        emissive = '#656565'
        emissiveIntensity = {0.2}
    />
    )
}

function BottomPartMaterial () {
    const isarLogoV = useLoader(TextureLoader, '../IsarAerospaceLogoV.png')
    return (
    <meshStandardMaterial 
        map={isarLogoV}
        color='#ffffff' 
        roughness = {0.01} 
        metalness = {0.8}
        reflectivity = {0.4} 
        side="DoubleSide" 
        emissive = '#656565'
        emissiveIntensity = {0.2}
    />
    )
}

function SunMaterial () {
    const sun = useLoader(TextureLoader, '../2k_sun.jpg')
    
    return <meshStandardMaterial map={sun}/>
}

function EarthMaterial () {
    const earth = useLoader(TextureLoader, '../2k_earth_daymap.jpg')
    
    return <meshStandardMaterial map={earth}/>
}

function MoonMaterial () {
    const panelMap = useLoader(TextureLoader, '../2k_moon.jpg')
    return <meshStandardMaterial map={panelMap}/>
}

//GEOMETRIES
function HeadGeometry () {
    const points = [];
    for ( let i = 0; i < 10; i ++ ) {
        points.push( new THREE.Vector2( Math.sin( i * 0.18 ) * 10 + 5, ( i - 5 ) * 2 ) );
    }

    return <primitive object={new LatheGeometry(points,48)} attach="geometry"/>
}

const Flashlight = () => {
    const lightRef = useRef();
    const { camera } = useThree();
  
    // Function to update the light's position and direction based on the camera
    const updateLight = () => {
      if (lightRef.current && camera) {
        // Get the camera's position
        const cameraPosition = camera.position.clone();
  
        // Get the camera's forward direction
        const cameraDirection = new THREE.Vector3();
        camera.getWorldDirection(cameraDirection);
  
        // Update the light's position and direction
        lightRef.current.position.copy(cameraPosition);
        lightRef.current.lookAt(cameraPosition.clone().add(cameraDirection));
      }
    };
  
    // Subscribe to the render loop to keep updating the light's position and direction
    useFrame(() => {
      updateLight();
    });
  
    return (
      <spotLight
        ref={lightRef}
        intensity={10.5} 
        distance={15} 
        angle={Math.PI / 6} // Adjust the cone angle as needed
        penumbra={0.2} // Adjust the penumbra as needed
        castShadow // Enable shadows if desired
      />
    );
};

export default function Spectrum (props) {
    const eng = props.eng
    const module = useRef()
    useFrame((state,delta) => {
        module.current.rotation.y += delta*0.3
        module.current.rotation.x += delta*0.002
    })

    const [earth_des,setEarth_des]=useState(false)
    const [module_des,setModule_des]=useState(false) 


    return <>
            <OrbitControls />
            <pointLight position={[0, -500, 150]} intensity={2} color={"#FFFFFF"}/>
            <pointLight position={[50, -20, 0]} intensity={2} color={"#edf4ff"}/>
            <pointLight position={[0, 40, 0]} intensity={0.1} color={"#a8a8a8"}/>
            <Flashlight/>
            <ambientLight intensity={0.5} color={"#0a0040"}/>
            <Sparkles count={50} scale={20} size={1} speed={0.5} color={"#feffc7"}/>

            <Stars 
            radius={100} 
            depth={150} 
            count={5000} 
            factor={3.2} 
            saturation={0} 
            fade speed={1} />
           {/*SUN*/} 
            <mesh position={[0,-500,150]} onClick={()=>setEarth_des(!earth_des)}>
                <sphereGeometry args={[5]}/>
                <SunMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[2,60,0]}
                    center
                    distanceFactor = {100}
                >
                     {eng? "Sun" : "太陽"}
                </Html>}
                <EffectComposer>
                    <Bloom luminanceThreshold={0.1} luminanceSmoothing={0.8} intensity={1} />
                </EffectComposer>
            </mesh>
           {/*EARTH*/} 
            <mesh 
            position={[40,-0,0]} 
            rotation={[Math.PI*0.6,-0.45,Math.PI*0.45]}
            onClick={()=>setEarth_des(!earth_des)}
            >
                <sphereGeometry args={[15]}/>
                <EarthMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[0,-5,0]}
                    center
                    distanceFactor = {5}
                >
                     {eng? "Earth" : "地球"}
                </Html>}
            </mesh>
            {/*MOON*/} 
            <mesh position={[0,40,0]} onClick={()=>setEarth_des(!earth_des)}>
                <sphereGeometry args={[1]}/>
                <MoonMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[0,-10,4]}
                    center
                    distanceFactor = {10}
                >
                     {eng? "Moon" : "月"}
                </Html>}
            </mesh>
            {/*Module*/} 
            <group 
            position={[0,-0.00,0]}
            ref={module} 
            rotation-z={-Math.PI*0.01} 
            onClick={()=>setModule_des(!module_des)}
            >            
                {/*Main part top*/} 
                <group position-y={5.00}>
                    {/*mainpart_t_1*/} 
                    {props.sound && <PositionalAudio autoplay loop url="/space_music.mp3" distance={3} />}              
                    <mesh position={[0,1.51,0]}>
                        <cylinderGeometry 
                        args={[0.7562, 0.7562, 1.8, 48]}  />
                        <TopPartMaterial/>
                    </mesh>
                    <mesh 
                        scale={[0.051,0.12,0.05]} 
                        position={[0, 3.348, 0]} 
                        rotation={[Math.PI-0, 0, 0]}       
                    >
                        <HeadGeometry/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0, 4.45, 0]}> {/* Adjust the position to sit at the cone tip */}
                        <sphereGeometry args={[0.271, 30, 30]} /> {/* Radius of the rounded tip */}
                        <MetalLightMaterial />
                    </mesh>
                    {/*hooks*/}
                    <mesh position={[0,0.645,0]}>
                        <boxGeometry args={[0.08,0.08,1.55]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,0.645,0]}
                          rotation={[0,Math.PI/2,0]}  >
                        <boxGeometry args={[0.08,0.08,1.55]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,0.645,0]}
                          rotation={[0,Math.PI/4,0]}  >
                        <boxGeometry args={[0.08,0.08,1.55]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,0.645,0]}
                          rotation={[0,-Math.PI/4,0]}  >
                        <boxGeometry args={[0.08,0.08,1.55]}/>
                        <MetalLightMaterial/>
                    </mesh>
                </group>
                {/*Main part middle*/}
                <group position-y={2.40}>  
                    <mesh position={[0,3.1,0]}>
                        <cylinderGeometry 
                        args={[0.7562, 0.7562, 0.2, 48]}
                       
                        />
                        <MetalBlueMaterial/>
                    </mesh>   
                    <mesh position={[0,1.75,0]}>
                        <cylinderGeometry 
                        args={[0.7562, 0.7562, 2.5, 48]}  
                      
                        />
                        <MiddlePartMaterial/>
                    </mesh>
                    <mesh position={[0,1.75,0.55]}>
                        <boxGeometry args={[0.2,2.5,0.5]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <group position = {[-2,-0.45,-0.6]}>
                        <mesh position={[2, 1, -0.1]}>
                            <boxGeometry args={[0.3,0.1, 0.3]} /> {/* Width, Height, Depth */}
                            <MetalLightMaterial/>
                        </mesh>
                        <mesh position={[2, 1.05,-0.1]} rotation={[0, 0, Math.PI / 2]}>
                            <cylinderGeometry args={[0.15, 0.15, 0.3, 3, 1, false, 0, Math.PI]} />
                            <MetalLightMaterial/>
                        </mesh>
                    </group>
                </group>
                 {/*Main part bottom*/}
                <group position-y={-4.0}>   
                    <mesh position={[0,6.8,0]}>
                        <cylinderGeometry args={[0.7562, 0.7562, 0.2, 48]}
                        receiveShadow/>
                        <MetalBlueMaterial/>
                    </mesh>   
                    <mesh position={[0,1.95,0]}>
                        <cylinderGeometry args={[0.7562, 0.7562, 9.5, 48]}  castShadow receiveShadow/>
                        <BottomPartMaterial/>
                    </mesh>
                    <mesh position={[0,1.97,0.55]}>
                        <boxGeometry args={[0.2,9.45,0.5]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <group position = {[-2,-3.7,-0.58]}>
                        <mesh position={[2, 1, 0.01]}>
                            <boxGeometry args={[0.5,0.2, 0.5]} /> {/* Width, Height, Depth */}
                            <MetalLightMaterial/>
                        </mesh>
                        <mesh position={[2, 1.1,-0.09]} rotation={[0, 0, Math.PI / 2]}>
                            <cylinderGeometry args={[0.15, 0.15, 0.5, 3, 1, false, 0, Math.PI]} />
                            <MetalLightMaterial/>
                        </mesh>
                    </group>
                </group>
                {/*Jets*/}
                <group  position-y={-7.25}>
                    {/*Jet ring*/}
                    <mesh position={[0,0.05,0]}>
                        <cylinderGeometry 
                        args={[0.77, 0.77, 0.8, 32]}
                        castShadow
                        receiveShadow
                        />
                        <MetalBlueMaterial/>
                    </mesh>
                    <Jet pos={[0,0,0]}/>
                    <Jet pos={[0.5,0,0]} rot={[0,Math.PI /2,0]}/>
                    <Jet pos={[-0.5,0,0]} rot={[0,Math.PI /2,0]}/>
                    <Jet pos={[0,0,0.5]} rot={[0,0,0]}/>
                    <Jet pos={[0,0,-0.5]} rot={[0,0,0]}/>
                    <Jet pos={[0.38,0,0.38]} rot={[0,Math.PI /4,0]}/>
                    <Jet pos={[-0.38,0,-0.38]} rot={[0,Math.PI /4,0]}/>
                    <Jet pos={[0.38,0,-0.38]} rot={[0,Math.PI /12,0]}/>
                    <Jet pos={[-0.38,0,0.38]} rot={[0,Math.PI /12,0]}/>
                </group>               
            </group>
            {/*Descriptions*/}
            {module_des && <group position={[0,10,0]}>
                <mesh position={[4,-9,0]}>
                        <boxGeometry args={[0.01,19,0.01]}/>
                        <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                        <Text              
                        fontSize = { 0.4 }
                        color = "salmon"
                        position={[0.5,0,0]}
                        textAlign = "center"
                    >
                        28m
                    </Text>
                </mesh>
                <mesh position={[0,-20,0]}>
                        <boxGeometry args={[3,0.01,0.01]}/>
                        <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                        <Text              
                        fontSize = { 0.4 }
                        color = "salmon"
                        position-y = { -0.3 }
                        textAlign = "center"
                    >
                        2m
                    </Text>
                </mesh>
                <mesh position={[-5,-10,0]}>
                    <sphereGeometry args={[0.1]}/>
                    <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                    <Text              
                        fontSize = { 0.3 }
                        color = "salmon"
                        position-x = { -1 }
                        textAlign = "center"
                    >
                       1000kg LEO
                    </Text>
                </mesh>
                <mesh position={[-5,-11,0]}>
                    <sphereGeometry args={[0.1]}/>
                    <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                    <Text              
                        fontSize = { 0.3 }
                        color = "salmon"
                        position-x = { -1 }
                        textAlign = "center"
                    >
                       700kg SSO
                    </Text>
                </mesh>
                <mesh position={[-4,-19,0]}>
                    <sphereGeometry args={[0.1]}/>
                     <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                    <Text              
                        fontSize = { 0.3 }
                        color = "salmon"
                        position-x = { -1.6 }
                        textAlign = "center"
                    >
                       9 engines (1st stage)
                    </Text>
                </mesh>
                <mesh position={[-2.5,-18.5,0]} rotation={[0,0,0.3]}>
                    <boxGeometry args={[2.5,0.01,0.01]}/>
                    <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                </mesh>
                <mesh position={[-4,-6,0]}>
                    <sphereGeometry args={[0.1]}/>
                     <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                    <Text              
                        fontSize = { 0.3 }
                        color = "salmon"
                        position-x = { -1.6 }
                        textAlign = "center"
                    >
                       1 engine (2st stage)
                    </Text>
                </mesh>
                <mesh position={[-2.5,-6.5,0]} rotation={[0,0,-0.3]}>
                    <boxGeometry args={[2.5,0.01,0.01]}/>
                    <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                </mesh>
            </group>}
    </>
}
