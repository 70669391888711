import "./App.css";
import "./Range.css";
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { useState } from "react";

import Login from "./Components/Login";
import Home from "./Components/Home";
import Ispace from "./Components/Ispace";
import Story from "./Components/Story";
import IspaceModulScene from "./Components/IspaceModulScene"
import IsarModulScene from "./Components/IsarModulScene"
import Isaraerospace from "./Components/Isaraerospace"

function App() {

  const [eng, setEng] = useState(true);
  const engHandler = (value) => {
    setEng(value);
  };

  const [help, setHelp] = useState(false);
  const helpHandler = (value) => {
    setHelp(value);
  };

  
  const router = createBrowserRouter([
  {path:'/', element:
    <Login onEng={(val) => engHandler(val)} eng={eng}/>},
  {path:'/home', element:
    <Home onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/ispace', element:
    <Ispace onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/ispace/story', element:
    <Story onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/ispace/hakuto-r', element: 
    <IspaceModulScene onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/isaraerospace', element: 
      <Isaraerospace onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/isaraerospace/spectrum', element: 
    <IsarModulScene onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>}  
  ])

  return (
    <>
      <RouterProvider router={router}/>
    </>
  )

}

export default App;
