//MATERIALS
import * as THREE from 'three'
import {useMemo} from 'react'
import Propulsion from './Propulsion'

export default function Jet (props) {

    function MetalDarkMaterial() {
        const material = useMemo(
          () => (
            new THREE.MeshPhysicalMaterial({
              color: '#151515',
              roughness: 0.2,
              metalness: 0.8,
              reflectivity: 0.9,
              emissive: new THREE.Color('#252525'),
              side: THREE.DoubleSide,
            })
          ),
          [] 
        );
      
        return <primitive object={material} attach="material" />;
      }
    
      function MetalPitchDarkMaterial() {
        const material = useMemo(
          () =>
            new THREE.MeshPhysicalMaterial({
              color: '#050505',
              roughness: 0.8,
              metalness: 0.4,
              reflectivity: 0.6,
              emissive: new THREE.Color('#050505'),
              side: THREE.DoubleSide,
            }),
          []
        );
      
        return <primitive object={material} attach="material" />;
      }

    function JetGeometry() {
        const geometry = useMemo(() => {
          const points = [];
          for (let i = 0; i < 10; i++) {
            points.push(new THREE.Vector2(Math.sin(i * 0.2) * 10 + 5, (i - 5) * 2));
          }
          return new THREE.LatheGeometry(points);
        }, []); 
      
        return <primitive object={geometry} attach="geometry" />;
    }

    return (

        <group position={props.pos} rotation={props.rot}>
            <mesh position={[0,-0.5,0]}>
                <sphereGeometry args={[0.1]}/>
                <meshBasicMaterial color={'#a6aaff'}/>
            </mesh>
            <mesh position={[0.01,-0.39,0.01]}>
                <cylinderGeometry 
                args={[0.2, 0.2, 0.08, 6]}
                castShadow
                receiveShadow
                />
                <MetalPitchDarkMaterial/>
            </mesh>
            <mesh 
                scale={[0.01,0.025,0.01]} 
                position={[0,-0.48,0]} 
                rotation={[Math.PI, 0, 0]}
                receiveShadow
                >
                <JetGeometry/>
                <MetalDarkMaterial/>
            </mesh>
            <Propulsion/>
        </group> 

    )
}