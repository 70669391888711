import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import * as THREE from 'three';

// Define the custom shader material with transparency and edge softening
const FlameMaterial = shaderMaterial(
  { time: 0, transparent: true, opacity: 0.6 },
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  `
    uniform float time;
    varying vec2 vUv;

    void main() {
      float intensity = 1.0 - length(vUv - 0.5);
      intensity = smoothstep(0.1, 1.0, intensity);

      // Light blue color gradient
      vec3 color = mix(vec3(0.4, 0.6, 1.0), vec3(0.8, 0.9, 1.0), intensity);

      // Flicker effect with partial dimming
      color *= 0.5 + 0.5 * sin(time * 3.0) * 0.5 + 0.75;

      // Set alpha based on intensity and vertical position for gradient fade at the top
      float alpha = (0.4 + intensity * 0.6) * (1.0 - vUv.y * 0.8); // More transparent at the top
      gl_FragColor = vec4(color * intensity, alpha);
    }
  `
);

function Propulsion() {
  const flameMaterial = useMemo(() => {
    const material = new FlameMaterial();
    material.transparent = true; // Enable transparency
    material.depthWrite = false; // Prevent depth writing for proper blending
    material.blending = THREE.AdditiveBlending; // Additive blending for better flame effect
    return material;
  }, []);

  // Update the flame material's time uniform for animation
  useFrame((state) => {
    flameMaterial.uniforms.time.value = state.clock.getElapsedTime();
  });

  return (
    <group position={[0,-1.49,0]} rotation={[Math.PI,0,0]}>
      {/* Light for glow effect */}
      <pointLight
        color="lightblue"
        intensity={2}
        distance={5}
        decay={2}
        position={[0, -0.5, 0]}
      />

      {/* Shader flame effect on a 3D Cone with gradient transparency */}
      <mesh position={[0, -0.6, 0]}>
        <coneGeometry args={[0.11, 0.42, 16, 1, true]} />
        <primitive object={flameMaterial} attach="material" />
      </mesh>
    </group>
  );
}

export default Propulsion;
