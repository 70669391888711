import { useFrame, useLoader, useThree } from '@react-three/fiber'
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { useRef, useState } from 'react'
import * as THREE from 'three'
import {LatheGeometry} from 'three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { OrbitControls, Html, Stars, Sparkles, Text, Float, PositionalAudio } from '@react-three/drei'
import "./Modul.css";
import Leg from "./Leg.js"

//MATERIALS
function MetalLightMaterial () {
    return <meshPhysicalMaterial color='#ababab' roughness = {0.4} metalness = {0.6}
    reflectivity = {0.65} side="DoubleSide" emissive = "#242424" />
}

function MetalDarkMaterial () {
    return <meshPhysicalMaterial color='#6e6e6e' roughness = {0.4} metalness = {0.6}
    reflectivity = {1} emissive = "#000000" side={THREE.DoubleSide} />
}

function PanelMaterial () {
    const panelMap = useLoader(TextureLoader, '../panel.jpg')
    return <meshStandardMaterial color='#737170' map={panelMap} metalness={0.2} roughness={0.5}/>
}

function InvestorMaterialT () {
    const panelMap = useLoader(TextureLoader, '../InvestorsPanelT.png')
    return <meshStandardMaterial map={panelMap} metalness={0.2} roughness={0.5}/>
}

function InvestorMaterial () {
    const panelMap = useLoader(TextureLoader, '../InvestorsPanel.png')
    return <meshStandardMaterial map={panelMap} metalness={0.2} roughness={0.5}/>
}

function SunMaterial () {
    const sun = useLoader(TextureLoader, '../2k_sun.jpg')
    
    return <meshStandardMaterial map={sun}/>
}

function EarthMaterial () {
    const earth = useLoader(TextureLoader, '../2k_earth_daymap.jpg')
    
    return <meshStandardMaterial map={earth}/>
}

function MoonMaterial () {
    const panelMap = useLoader(TextureLoader, '../2k_moon.jpg')
    return <meshStandardMaterial map={panelMap}/>
}

//GEOMETRIES
function BottomGeometry () {
    return <cylinderGeometry args={[0.55, 0.7, 1, 32]}/>
}

function JetGeometry () {
    const points = [];
    for ( let i = 0; i < 10; i ++ ) {
	points.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10 + 5, ( i - 5 ) * 2 ) );
    }

    return <primitive object={new LatheGeometry(points)} attach="geometry"/>
}


const Flashlight = () => {
    const lightRef = useRef();
    const { camera } = useThree();
  
    // Function to update the light's position and direction based on the camera
    const updateLight = () => {
      if (lightRef.current && camera) {
        // Get the camera's position
        const cameraPosition = camera.position.clone();
  
        // Get the camera's forward direction
        const cameraDirection = new THREE.Vector3();
        camera.getWorldDirection(cameraDirection);
  
        // Update the light's position and direction
        lightRef.current.position.copy(cameraPosition);
        lightRef.current.lookAt(cameraPosition.clone().add(cameraDirection));
      }
    };
  
    // Subscribe to the render loop to keep updating the light's position and direction
    useFrame(() => {
      updateLight();
    });
  
    return (
      <spotLight
        ref={lightRef}
        intensity={1.5} 
        distance={30} 
        angle={Math.PI / 6} // Adjust the cone angle as needed
        penumbra={0.2} // Adjust the penumbra as needed
        castShadow // Enable shadows if desired
      />
    );
  };


export default function Modul (props) {
    const eng = props.eng
    const module = useRef()
    useFrame((state,delta) => {
        module.current.rotation.y += delta*0.2
    })

    const [earth_des,setEarth_des]=useState(false)
    const [module_des,setModule_des]=useState(false)
    
    return <>
            <OrbitControls />
            <pointLight position={[0, -500, 150]} intensity={2} color={"#FFFFFF"}/>
            <pointLight position={[0, -20, 0]} intensity={1.5} color={"#b0ceff"}/>
            <pointLight position={[0, 40, 0]} intensity={0.5} color={"#a8a8a8"}/>
            <Flashlight/>
            <ambientLight intensity={0.1} color={"#0a0040"}/>
            <Sparkles count={50} scale={20} size={1} speed={0.5} color={"#feffc7"}/>

            <Stars radius={100} depth={150} count={5000} factor={4} saturation={0} fade speed={1} />
           {/*SUN*/} 
            <mesh position={[0,-500,150]} onClick={()=>setEarth_des(!earth_des)}>
                <sphereGeometry args={[5]}/>
                <SunMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[2,60,0]}
                    center
                    distanceFactor = {100}
                >
                     {eng? "Sun" : "太陽"}
                </Html>}
                <EffectComposer>
                    <Bloom luminanceThreshold={0.1} luminanceSmoothing={0.8} intensity={1} />
                </EffectComposer>
            </mesh>
           {/*EARTH*/} 
            <mesh position={[0,-20,0]} rotation={[Math.PI*0.6,-0.45,Math.PI*0.45]}
                  onClick={()=>setEarth_des(!earth_des)}
            >
                <sphereGeometry args={[2]}/>
                <EarthMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[0,-5,0]}
                    center
                    distanceFactor = {10}
                >
                     {eng? "Earth" : "地球"}
                </Html>}
            </mesh>
            {/*MOON*/} 
            <mesh position={[0,40,0]} onClick={()=>setEarth_des(!earth_des)}>
                <sphereGeometry args={[1]}/>
                <MoonMaterial/>
                {earth_des &&
                <Html 
                    wrapperClass="label" 
                    position={[0,-10,4]}
                    center
                    distanceFactor = {10}
                >
                     {eng? "Moon" : "月"}
                </Html>}
            </mesh>
            {/*Module*/} 
            <group ref={module} rotation-y={-Math.PI*0.25} onClick={()=>setModule_des(!module_des)}>  
       
               
                {/*Main part top*/} 
                <group position-y={1.85}>
                    {/*mainpart_t_1*/} 
              
                    {props.sound && <PositionalAudio autoplay loop url="/space_music.mp3" distance={3} />}
               
                    <mesh position ={[0,0,0.15]} receiveShadow>
                        <boxGeometry args={[2,0.902,0.8]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_2*/} 
                    <mesh position ={[0.17,0,0.2]} rotation-y = {Math.PI * 0.5} receiveShadow castShadow>
                        <boxGeometry args={[1.6, 0.9, 0.7]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_2b*/} 
                    <mesh position ={[-0.33,0,0]} rotation-y = {Math.PI * 0.5} receiveShadow castShadow>
                        <boxGeometry args={[ 2, 0.902, 0.25]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_2b_detail*/} 
                    <mesh position ={[-0.23,0.43,0.8]} receiveShadow castShadow>
                        <boxGeometry args={[0.5, 0.05, 0.5]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_3*/} 
                    <mesh position ={[0.75,-0.1,-0.75]}  rotation-y = {-Math.PI * 0.25} castShadow>
                        <boxGeometry args={[0.1, 1.1, 0.05]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_3b*/} 
                    <mesh position ={[0.66,-0.12,-0.66]}  rotation-y = {-Math.PI * 0.25} castShadow>
                        <boxGeometry args={[ 0.2, 0.25, 0.2, 3, 5 ,3]}/>
                        <meshBasicMaterial wireframe color="#bdbdbd"/>
                    </mesh>
                    {/*mainpart_t_3t*/} 
                    <mesh position ={[0.45,0.44,-0.45]}  rotation-y = {-Math.PI * 0.25} castShadow>
                        <cylinderGeometry args={[ 0.5, 0.5, 0.06, 6]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_3ma*/} 
                    <mesh position ={[0.65,0,-0.65]}  rotation-y = {-Math.PI * 0.25} castShadow receiveShadow>
                        <cylinderGeometry args={[ 0.2, 0.2, 0.08, 3]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_3mb*/} 
                    <mesh position ={[0.65,-0.25,-0.65]}  rotation-y = {-Math.PI * 0.25} castShadow receiveShadow>
                        <cylinderGeometry args={[  0.2, 0.15, 0.08, 3]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_4*/} 
                    <mesh position ={[0,0,0]} rotation-y = {-Math.PI * 0.25} castShadow>
                        <boxGeometry args={[2.19, 0.903, 0.62]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_roof*/} 
                    <mesh position ={[0.2,0.45,0.8]} castShadow receiveShadow>
                        <cylinderGeometry args={[  0.05, 0.05, 0.2, 32]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*mainpart_t_rooft*/} 
                    <mesh position ={[0.2,0.55,0.8]}  rotation-y = {-Math.PI * 0.25} castShadow receiveShadow>
                        <boxGeometry args={[0.1, 0.01, 0.13]}/>
                        <MetalLightMaterial/>
                    </mesh>
                </group>

                {/*Main part bottom*/}
                <group position-y={1}>
                    {/*mainpart_1*/} 
                    <mesh position ={[0,0,0]} receiveShadow castShadow>
                        <boxGeometry args={[2, 0.75, 1.1 ]}/>
                        <MetalLightMaterial/>
                    </mesh>

                    {/*mainpart_2*/} 
                    <mesh position ={[0,0,0]} rotation-y ={Math.PI * 0.5} receiveShadow castShadow>
                        <boxGeometry args={[ 2, 0.75, 1.1]}/>
                        <MetalLightMaterial/>
                    </mesh>

                    {/*mainpart_3*/} 
                    <mesh position ={[0,-0.15,0]} rotation-y ={Math.PI * 0.25} receiveShadow castShadow>
                        <boxGeometry args={[2.25, 1, 0.6]}/>
                        <MetalLightMaterial/>
                    </mesh>

                    {/*mainpart_4*/} 
                    <mesh position ={[0,-0.15,0]} rotation-y ={-Math.PI * 0.25} receiveShadow castShadow>
                        <boxGeometry args={[2.25,1, 0.6]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    
                    {/*Legs*/} 
                    <Leg legrot={[0,0,0]}/>
                    <Leg legrot={[0,Math.PI * 0.5,0]}/>
                    <Leg legrot={[0,Math.PI,0]}/>
                    <Leg legrot={[0,-Math.PI * 0.5,0]}/>
                </group>

                {/*Bottom*/}
                <group position-y={0.9}>
                    {/*base*/}
                    <mesh position={[0,-0.65,0]}>
                        <cylinderGeometry args={[1.05,0.8,0.4,32]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,-0.1,0.499]} rotation={[Math.PI * 0.5,0,0]} receiveShadow castShadow>
                            <BottomGeometry/>
                            <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,-0.1,-0.499]} rotation={[-Math.PI * 0.5,0,0]} receiveShadow castShadow>
                            <BottomGeometry/>
                            <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[-0.499,-0.1,0]} rotation={[Math.PI * 0.5,0,Math.PI * 0.5]} receiveShadow castShadow>
                            <BottomGeometry/>
                            <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0.499,-0.1,0]} rotation={[Math.PI * 0.5,0,-Math.PI * 0.5]} receiveShadow castShadow>
                            <BottomGeometry/>
                            <MetalLightMaterial/>
                    </mesh>
                </group>

                {/*Jets*/}
                <group>
                    {/*Jet ring*/}
                    <mesh position={[0,0.05,0]}>
                        <cylinderGeometry args={[0.77, 0.77, 0.075, 32]}  castShadow receiveShadow/>
                        <MetalLightMaterial/>
                    </mesh>
                    <mesh position={[0,-0.1,0]}>
                        <sphereGeometry args={[0.1]}/>
                        <meshBasicMaterial color={'#a6aaff'}/>
                    </mesh>
                   

                        {/*Jet main*/}
                    <mesh scale={[0.01,0.025,0.01]} position={[0,-0.1,0]} rotation={[Math.PI,Math.PI,0]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>
                        {/*Jet 1*/}
                    <mesh scale={[0.005,0.022,0.005]} position={[-0.48,-0.1,0.48]} rotation={[Math.PI+0.1, 0,-Math.PI*0.05]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>
                        {/*Jet 2*/}
                        <mesh scale={[0.005,0.022,0.005]} position={[0.48,-0.1,-0.48]} rotation={[Math.PI-0.1, 0,Math.PI*0.05 ]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>

                        {/*Jet 3*/}                   
                    <mesh scale={[0.005,0.022,0.005]} position={[0,-0.1,0.5]} rotation={[Math.PI+0.3, 0, -Math.PI*0.04]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>

                    {/*Jet 4*/}
                    <mesh scale={[0.005,0.022,0.005]} position={[0,-0.1,-0.5]} rotation={[Math.PI-0.3, 0, 0]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>

                    {/*Jet 5*/}
                    <mesh scale={[0.005,0.022,0.005]} position={[-0.5,-0.1,0]} rotation={[Math.PI, 0, -0.3]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh>

                    {/*Jet 6*/}
                    <mesh scale={[0.005,0.022,0.005]} position={[0.5,-0.1,0]} rotation={[Math.PI, 0, 0.3]} receiveShadow>
                        <JetGeometry/>
                        <MetalDarkMaterial/>
                    </mesh >
                </group>

                {/*Details*/}
                <group>
                    {/*panel_1_r*/}
                    <mesh position={[1.03,1.05,0.3]} receiveShadow castShadow> 
                        <boxGeometry args={[0.02,0.6,0.5]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_2_r*/}
                    <mesh position={[1.03,1.05,-0.35]} receiveShadow castShadow>
                        <boxGeometry args={[0.02,0.6,0.45]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_3_r*/}
                    <mesh position={[1.04,0.58,0.35]} receiveShadow castShadow>
                        <boxGeometry args={[0.02,0.25,0.40]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_4_r*/}
                    <mesh position={[1.03,1.88,0.18]} receiveShadow castShadow>
                        <boxGeometry args={[0.02,0.8,0.7]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_4_r_detail*/}
                    <mesh position={[1.03,2.17,-0.05]} receiveShadow castShadow>
                        <boxGeometry args={[0.03,0.25,0.25]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*panel_4_r_detailb*/}
                    <mesh position={[1.03,2.1,0]} receiveShadow castShadow>
                        <boxGeometry args={[0.08,0.07,0.07]}/>
                        <MetalLightMaterial/>
                    </mesh>

                    {/*panel_1_l*/}
                    <mesh position={[0.3, 1.05, 1.03]} receiveShadow castShadow> 
                        <boxGeometry args={[0.5,0.6,0.02]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_2_l*/}
                    <mesh position={[-0.35,1.05,1.03]} receiveShadow castShadow>
                        <boxGeometry args={[0.45,0.6,0.02]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_3_l*/}
                    <mesh position={[0.35,0.58,1.04]} receiveShadow castShadow>
                        <boxGeometry args={[0.40,0.25,0.02]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_4_l*/}
                    <mesh position={[0.18,1.88,1.03]} receiveShadow castShadow>
                        <boxGeometry args={[0.7,0.8,0.02]}/>
                        <PanelMaterial/>
                    </mesh>
                    {/*panel_4_l_detail*/}
                    <mesh position={[-0.05,2.17,1.03]} receiveShadow castShadow>
                        <boxGeometry args={[0.25,0.25,0.03]}/>
                        <MetalLightMaterial/>
                    </mesh>
                    {/*panel_4_l_detailb*/}
                    <mesh position={[0,2.1,1.03]} receiveShadow castShadow>
                        <boxGeometry args={[0.07,0.07,0.08]}/>
                        <MetalLightMaterial/>
                    </mesh>

                    {/*panel_investors_top*/}
                    <mesh position={[0.8,1.93,0.8]} rotation-y={-Math.PI*0.25} receiveShadow castShadow>
                        <boxGeometry args={[0.02,0.7,0.6]}/>
                        <InvestorMaterialT/>
                    </mesh>
                    {/*panel_investors_bottom*/}
                    <mesh position={[0.8,0.85,0.8]} rotation-y={-Math.PI*0.25} receiveShadow castShadow>
                        <boxGeometry args={[0.02,1,0.6]}/>
                        <InvestorMaterial/>
                    </mesh>
                    {/*ring1*/}
                    <mesh position={[0,1.4,0]} receiveShadow castShadow>
                        <boxGeometry args={[2.08, 0.05, 1.18]}/>
                        <MetalDarkMaterial/>
                    </mesh>
                    {/*ring2*/}
                    <mesh position={[0,1.4,0]} receiveShadow castShadow>
                        <boxGeometry args={[1.18, 0.05, 2.08]}/>
                        <MetalDarkMaterial/>
                    </mesh>
                    {/*ring3*/}
                    <mesh position={[0,1.4,0]} rotation-y={Math.PI * 0.25} receiveShadow castShadow>
                        <boxGeometry args={[ 0.635, 0.05, 2.31]}/>
                        <MetalDarkMaterial/>
                    </mesh>

                    {/*ring4*/}
                    <mesh position={[0,1.4,0]} rotation-y={-Math.PI * 0.25} receiveShadow castShadow>
                        <boxGeometry args={[ 0.635, 0.05, 2.31]}/>
                        <MetalDarkMaterial/>
                    </mesh>

                </group>
                

            </group>
            {/*Descriptions*/}
            {module_des && <group>
                <mesh position={[4,1,0]}>
                        <boxGeometry args={[0.01,4,0.01]}/>
                        <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                        <Text              
                        fontSize = { 0.4 }
                        color = "salmon"
                        position={[0.5,0,0]}
                        textAlign = "center"
                    >
                        2.3m
                    </Text>
                </mesh>
                <mesh position={[0,-2,0]}>
                        <boxGeometry args={[6,0.01,0.01]}/>
                        <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                        <Text              
                        fontSize = { 0.4 }
                        color = "salmon"
                        position-y = { -0.3 }
                        textAlign = "center"
                    >
                        2.6m
                    </Text>
                </mesh>
                <mesh position={[-4,1,0]}>
                    <sphereGeometry args={[0.1]}/>
                    <meshBasicMaterial color={'rgb(255, 42, 0)'}/>
                    <Text              
                        fontSize = { 0.3 }
                        color = "salmon"
                        position-x = { -0.8 }
                        textAlign = "center"
                    >
                        340kg
                    </Text>
                </mesh>
            </group>}

            <Float>
                <Text              
                    fontSize = { 1.5 }
                    color = "salmon"
                    position-y = { 3 }
                    textAlign = "center"
                    rotation-y = {Math.PI * 0.25}
                >
                    !
                </Text>
            </Float>
    </>
}
