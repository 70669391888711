import {Link} from 'react-router-dom'
import "./IspaceModulComponents/Modul.css";
import {Canvas} from '@react-three/fiber'
import { useState, Suspense, useEffect } from 'react'
import {Loader } from "@react-three/drei"

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { useNavigate } from 'react-router-dom'

import Spectrum from './IsarModulComponents/Spectrum.js'
import HelperModule from "./HelperModule";

import sound_on from "../Images/sound-on.png";
import sound_off from "../Images/sound-off.png";

function IsarModulScene (props) {
const help = props.help
const eng = props.eng
const navigate = useNavigate();


const [sound,setSound] = useState(true)

useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          // ...
          //console.log("uid", uid)
        } else {
          // User is signed out
          // ...
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])

return (
    <div className="App-module">
    <div className="section_nav">
        <div className="nav-box" style={{opacity:"1"}}>
          <button onClick={()=> navigate("/home") }>H•ME</button>
          <button onClick={()=> navigate("/ispace")}>ISP⧍CE</button>
          <button onClick={()=> navigate("/isaraerospace")}>IS⧍R ⧍ER•SP⧍CE</button>
        </div>
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
    </div>
    <div className="helper-box">
          <button className="helper" onClick={()=>props.onHelp(!help)}>
            ?
          </button>
          <Link to="/isaraerospace" className="link-home">IS⧍R</Link>
         <div className="sound" onClick={()=>setSound(!sound)}>
             <img src={sound ? sound_on : sound_off} alt="sound on" />
         </div>   
    </div>
    <div className="modul-scene"> 
        <Canvas shadows camera={{position:[-30,-10,25], zoom:2}}>
            <Suspense fallback={null}>
                <Spectrum eng={props.eng} help={help} sound={sound}/>
            </Suspense>
        </Canvas>
        <Loader
            innerStyles={{
                height: '10px',
                width: '250px',
                borderRadius: '5px',
                backgroundColor:'#ebebeb',
                 }}
            barStyles={{
                height: '10px',
                width: '250px',
                borderRadius: '5px',
                backgroundColor:"orange"}}
            dataStyles={{
                fontSize: '14px'
            }}
            dataInterpolation={(p) => `Loading Hakuto-r module, ${p.toFixed(2)}%`}
            initialState={(active) => active}
            />  
    </div>
        {help &&
                <HelperModule
                onCloseHelper={(e) => props.onHelp(e)}
                onEng={(val) => props.onEng(val)}
                eng={eng}
                />
            }
        {sound?
        <div className="music-tribute">
                <span>Music by <a href="https://pixabay.com/users/audiocoffee-27005420/" target="_blank" rel="noreferrer">AudioCoffee</a> from <a href="https://pixabay.com/" target="_blank" rel="noreferrer">Pixabay</a></span>
        </div>:null}
    </div>

)
}

export default IsarModulScene