import "../App.css";
import "../Range.css";
import "./Home.css";
import { useRef, useEffect, useState } from "react";

import {Link} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom'

//img
import ispace_w from "../Images/logo_ispace_white.svg";
import anim_gr from "../Videos/ispace-logo-animation-concept-graphic.mp4"
import anim_bl from "../Videos/ispace-logo-animation-concept-min-black.mp4"
import anim_wt from "../Videos/ispace-logo-animation-concept-min-white.mp4"

function Home(props) {
  const eng = props.eng
  
  const top = useRef();
  const about = useRef();
  const video = useRef()
  const story = useRef()
  const module = useRef()

  const navigate = useNavigate();

  const [videoSelected,setVideoSelected] = useState(anim_gr)
  const videoSelectedHandler = (vid) => {
     setVideoSelected(vid)
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          //console.log("uid", uid)
        } else {
          // User is signed out
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])


  const scrollTo = (to) => {
    to.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
      const home = document.getElementById("home");
      const hook = document.getElementById("hook");
      const handleScroll = () => {
        if (home.scrollTop > hook.getBoundingClientRect().top + 200 ) {
          setShowMenu(true);
        } else {
          setShowMenu(false);
        }

      };
        
      home.addEventListener('scroll', handleScroll);
      return () => {
      home.removeEventListener('scroll', handleScroll);
      };
    },[]);
  

  return (
    <main className="home" id="home">
      <div
       className="background-fixed"      
       />
      <div className="section_nav">
        <div className="nav-box" style={{opacity: "1"}} >
          <button onClick={()=> navigate("/home") }>H•ME</button>
          <button onClick={()=> navigate("/ispace")}>ISP⧍CE</button>
          <button onClick={()=> navigate("/isaraerospace")}>IS⧍R ⧍ER•SP⧍CE</button>
        </div>
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
      </div>
      <div id="hook" ref={about} className="section_wrapper section_wrapper-top">
          <span className="heading_wrapper-l">
            <h4 className="heading heading-about">⧍B•UT</h4>
            <a class="a_link" title="Go to ispace official site" href="https://ispace-inc.com/" target="_blank" rel="noreferrer">
                  <img className="ispace_logo" alt="ispace logo" src={ispace_w}/>
                </a>
          </span>
          <div className="block_wrapper">
            <div className="section_block section_block-about">
            {eng === true ? (  
              <p>
                Ispace is a Japanese space startup which aims to be the first private company to land on the Moon. This section has 3 interactives to tributes their first mission -  <strong>Logo</strong>, <strong>Story</strong> and <strong>Module</strong>. 
              </p>
            ) : (
              <p>
ispaceは、日本の宇宙スタートアップであり、民間企業として初めて月面着陸を目指しています。このセクションでは、彼らの最初のミッションを称えるために、<strong>ロゴ</strong>、<strong>ストーリー</strong>、<strong>モジュール</strong>の3つのインタラクティブなコンテンツをご用意しています。
              </p>
            )}
            </div> 
            <span className="heading_wrapper-r"><h4 className="heading heading-logo">L•G•</h4></span>  
          </div>
          <div id="hook"  ref={video} className="section_wrapper section_wrapper-video"> 
            <div className="section_block section_block-video">         
                  {eng === true ? (
                    <p>
                      Why not to spice up a project with a little bit of motion to help catch interest of the target group?<br/>
                      This is a concept of a simple ispace logo animation, which could be use for example on social media, presentations or digital banners. There are 3 versions - one with texture in space (graphic), one with black background and one with white background. Made with Illustrator and After Effects.
                    </p>
                  ) : (
                    <p>
                      アニメーションは私の高校時代からの趣味です。映画や複雑なストーリーを作成することを目指していませんが、ターゲット層の興味を引くように、よくアニメーションを加えながら、魅力を加えます。
                      <br/>
                      ここはシンプルなispaceロゴアニメーションのコンセプトです。ソーシャルメディア、プレゼンテーション、またはデジタルバナーなどで使用できます。テクスチャのある宇宙のバックグラウンド(GRAPHIC)、黒いバックグラウンド(BLACK)、白いバックグラウンド(WHITE)の3つのバージョンがあります。IllustratorとAfter Effectsで制作しました。
                    </p>
                  )}
            </div>
            <div className="video__nav">
                <button className={videoSelected === anim_gr ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_gr)}}>GRAPHIC</button>
                <button className={videoSelected === anim_bl ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_bl)}}>BLACK</button>
                <button className={videoSelected === anim_wt ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_wt)}}>WHITE</button>
            </div>
            <div className="video__wrapper">
              <video src={`${videoSelected}#t=0.5`} type="video/mp4" width="100%" height="auto" controls controlsList="nodownload"/>
            </div>    
            <div className="block-video-background"/>  
          </div>
      </div>   
      <div  ref={story} className="section_wrapper-story">
        <div className="section_block section_block-story">    
          <div className="link_box link_box-story">
                <Link to="/ispace/story" className="link_home-story link-big">ST•RY</Link>
          </div>  
          <div className="section_block-text">
                    {eng === true ? (
                      <p>
                        I believe that space exploration attracts people who are curious and eager to discover answers.<br/>
                        The story app is a concept of a web about HAKUTO-R Mission 1, which combines providing information by engaging curiosity in visual and interactive environemnt.<br/>
                        <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                          Open by clicking the story logo. Explore and be curious. If you are visiting the page on a smartphone, rotate your screen to landscape mode for better experience.
                          After visiting the page, you can click <span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?</span> button to get more instructions. </p>
                      </p>
                    ) : (
                      <p>
                      ウェブデザイナーの仕事の一部はプロジェクトのターゲット層を分析することです。宇宙探査は好奇心を持ている人々と興味津々で答えを見つけることに熱心な人々を引き寄せると信じています。
                      このストーリーアプリは、HAKUTO-Rミッション1に関するウェブのコンセプトで、視覚的でインタラクティブな環境で好奇心を掻き立てる情報提供を組み合わせています。<br/>   
                      <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                      ストーリーロゴをクリックして開いてください。探索し、好奇心を持ってください。スマートフォンでページを訪れている場合は、より良い体験のために画面を横向きに回転させてください。ページを訪れた後、<span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?
                        </span>ボタンをクリックして指示を取得できます。
                        </p>
                      </p>
                    )}
          </div>
        </div>
      </div>
      <div ref={module} className="section_wrapper-module">
          <div className="section_block section_block-module">  
            <div className="link_box link_box-module">
              <Link to="/ispace/hakuto-r" className="link_home-module link-big">M•DULE</Link>
            </div>
            <div className="section_block-text section_block-text-module">
            {eng === true ? (
              <p>
                Hakuto-r mission was a first attempt of ispace startup to land on the moon. Here you can explore it's fan 3D model with interactive features and more details.  
                The provided scale, shape and design is not fully accurate and it is for entertainment only.      
                <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                Open by clicking the module logo. Explore and be curious. After visiting the page, you can click <span
                        style={{
                          color: "rgba(255, 68, 0, 1)",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >?</span> button to get more instructions. </p>
              </p>
            ):(
              <p>
                プロフェッショナルとして、私は技術の最新情報を追いかけ、常に新しいことを学ぶ必要があります。そして、一番いい学ぶ方法は実践だと思います。
                数ヶ月数か月前、私はWeb上の3DのフレームワークであるThree.jsを使用して、最初の3Dキューブを作成しました。<br/>
                今、完全な3DのHakuto-r Mission 1モジュールをインタラクティブにご紹介します。
                新しいモジュールを同様の方法で紹介するのはどうでしょうか。
                <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                      ストーリーロゴをクリックして開いてください。探索し、好奇心を持ってください。ページを訪れた後、<span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?
                        </span>ボタンをクリックして指示を取得できます。
                        </p>
              </p>
            )}

            </div>
          </div>
      </div>  
     
      <span className="photo-by">Background photo by <a className="a_link" href="https://unsplash.com/@danesduet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniel Olah</a> on <a className="a_link" href="https://unsplash.com/photos/HNkgPFBShSw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </span>
    </main>
  );
}

export default Home;
