import "../App.css";
import "../Range.css";
import "./Home.css";
import { useRef, useEffect, useState } from "react";


import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { useNavigate } from 'react-router-dom'
import ispace_w from "../Images/logo_ispace_white.svg";
import isar_logo from "../Images/Isar-Aerospace-logo.png"

function Home(props) {
  const eng = props.eng
  
  const top = useRef();
  const about = useRef();
  const startups = useRef()


  const navigate = useNavigate();


  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          //console.log("uid", uid)
        } else {
          // User is signed out
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])


  const scrollTo = (to) => {
    to.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
      const home = document.getElementById("home");
      const hook = document.getElementById("hook");
      const handleScroll = () => {
        if (home.scrollTop > hook.getBoundingClientRect().top + 200 ) {
          setShowMenu(true);
        } else {
          setShowMenu(false);
        }

      };
        
      home.addEventListener('scroll', handleScroll);
      return () => {
      home.removeEventListener('scroll', handleScroll);
      };
    },[]);
  

  return (
    <main className="home" id="home">
      <div
       className="background-fixed"      
       />
      <div className="section_nav">
        <div className="nav-box" style={showMenu? {transition:'1s', opacity: "1"}:{transition:'0.3s', opacity: "0"} } >
          <button onClick={()=> navigate("/home") }>H•ME</button>
          <button onClick={()=> navigate("/ispace")}>ISP⧍CE</button>
          <button onClick={()=> navigate("/isaraerospace")}>IS⧍R ⧍ER•SP⧍CE</button>
        </div>
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
      </div>
      <div ref={top} className="section_top">
          <button className="planet planet__b" onClick={()=> scrollTo(about)}>
            <div className="planet__inner">⧍B•UT</div>
          </button>
          <button className="planet planet__b"  onClick={()=> navigate("/ispace")}>
            <div className="planet__inner">ISP⧍CE</div>
          </button>
          <button className="planet planet__b" onClick={()=> navigate("/isaraerospace")}>
            <div className="planet__inner">IS⧍R ⧍ER•SP⧍CE</div>
          </button>
          <div className="planet__e">
            <div className="welcome-box">
              <h3>
                {eng === true
                  ? "WELC•ME T• SP⧋CEFIC⧋TI•N"
                  : "SP⧋CEFIC⧋TI•Nへようこそ。"}
              </h3>
            </div>
            <div className="welcome-bottom">
              <h4>
              {eng === true
                    ? "AN INTERACTIVE SITE CREATED FOR SPACE EXPLORERS"
                    : "宇宙探検家のために作られたインタラクティブサイト"}
              </h4>
            </div>
            <button className="scrolldown-btn" onClick={()=> scrollTo(about)}/>
          </div>
      </div>
      <div id="hook" ref={about} className="section_wrapper section_wrapper-top">
          <span className="heading_wrapper-l"><h4 className="heading heading-about">⧋B•UT</h4></span>
          <div className="block_wrapper">
            <div className="section_block section_block-about">
            {eng === true ? (  
              <p>
                Spacefication is a small project, created to showcase some of the interesting and promising space startups.
                The goal is to provide an interactive view of their technologies and missions. Currently there are 2 companies - <strong>Isar Aerospace</strong>, European space startup which will soon deploy its first orbital launch vehicle to the space, and <strong>ispace</strong>, Japanese space startup which aims to be the first private company to land on the Moon. <br/>               
                Please note, that I have no legal rights to the materials of these companies, their logos, visuals etc. it is solely private project, currently closed from the public view. 
              </p>
            ) : (
              <p>
                Spaceficationは、興味深く将来性のある宇宙スタートアップを紹介するために作成された小さなプロジェクトです。
                目的は、これらの企業の技術とミッションのインタラクティブなビューを提供することです。現在、2社のスタートアップが紹介されています。
                <strong>Isar Aerospace</strong>は、もうすぐ最初の軌道打ち上げ機を宇宙に送り出す予定のヨーロッパの宇宙スタートアップです！そして、<strong>ispace</strong>は、月面着陸を目指す日本の宇宙スタートアップであり、民間企業として初の月面着陸を目指しています。<br/>
                なお、これらの企業のロゴやビジュアルなどの資料に関する法的権利は一切なく、本プロジェクトは非公開で行っている個人的なプロジェクトであることをご了承ください。
              </p>
            )}
            </div> 
          </div>
      </div>   
      <div id="hook" ref={startups} className="section_wrapper section_wrapper-startups">
          <h4>TRIBUTE TO</h4>
          <div className="section_wrapper-startups-list">
              <div className="startups-list-item">
                <a class="a_link" title="Go to ispace official site" href="https://ispace-inc.com/" target="_blank" rel="noreferrer">
                  <img className="ispace_logo" alt="ispace logo" src={ispace_w}/>
                </a>
                <button title="Go to ispace aerospace section" className="planet planet__a planetX"  onClick={()=> navigate("/ispace")}>
                  <div className="planet__inner">ISP⧍CE</div>
                </button>
              </div>
              <div className="startups-list-item">
                <a class="a_link" title="Go to Isar aerospace official site" href="https://www.isaraerospace.com/" target="_blank" rel="noreferrer">
                  <img className="isar_logo" alt="ispace logo" src={isar_logo}/>
                </a>
                <button title="Go to Isar aerospace section" className="planet planet__a planetX" onClick={()=> navigate("/isaraerospace")}>
                  <div className="planet__inner">IS⧍R ⧍ER•SP⧍CE</div>
                </button>
              </div>
          </div>
      </div>
      <div className="section_author">
            <div className="section_block section_block-author">    
            <h4>⧋UTH•R</h4>    
              {eng === true ? (
                <p>
                  My name is Aleš and I am a web developer, creative and ispace fan and. :) This project was created with frontend technologies like
                  React.js framework, Three.js (React Three Fiber), HTML5, CSS3 etc. and with graphic software like Illustrator, Photoshop or After Effects.
                  You can contact me on this email address{" "}<a className="a_link" href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a> or you can visit <a className="a_link" href="https://www.ab-miniportfolio.com/">my personal website</a>. 
                </p>
              ) : (
                <p>
                  私はアレシュと申します。創造的にデベロッパーをして、ispaceのファンです。:) ここのプロジェクトはReact.jsやThree.js(React Three Fiber)やHTML5やCSS3などで作りました。グラフィックのためにIllustratorとPhotoshopとBlenderを利用しました。ご興味がある方は、<a className="a_link" href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a>のメールにご連絡ください。私の<a className="a_link" href="https://www.ab-miniportfolio.com/">パーソナルページ</a>を訪問してくさだら、幸いです。
                </p>
              )}</div>

      </div>  
      <span className="photo-by">Background photo by <a className="a_link" href="https://unsplash.com/@danesduet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniel Olah</a> on <a className="a_link" href="https://unsplash.com/photos/HNkgPFBShSw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </span>
    </main>
  );
}

export default Home;
